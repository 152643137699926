.productCard_container{
    background: #F2F6FA;
    padding: 20px;
    .productCard_block{
        max-width: 1200px;
        margin: 20px auto;
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 20px;
        img{ 
            border-radius: 16px;
            width: 97%;
        }
        @media screen and(max-width: 480px) {
        grid-template-columns: 100%;
        img{  
            width: 100%;
        }
        }
    }
}