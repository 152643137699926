.product_container{
    background: #F2F6FA;
    padding: 20px;
    .product_block{
        max-width: 1200px;
        margin: 0 auto;
        h2{
            color: #2B4594;
            font-family: 'Inter';
            font-size: 34.688px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%; /* 138.378% */            
        }
        .product_content{

            overflow-x: scroll;
            &::-webkit-scrollbar {
                display: none;
              }
            .product_item{
                width: 1200px;
                display: flex;
                align-items: center;
                gap: 20px;
                flex-wrap: wrap;
                // justify-content: space-between;
                margin: 30px 0;
                .product_card{
                    border-radius: 16px;
                    background: #FFF;
                    width: 180px;
                    height: 80px;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    padding: 0 10px;
                    h3{
                        color: #2B4594;
                        font-family: 'Inter';
                        font-size: 12.25px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 120%; /* 130.612% */                    
                    }
                }
            }
        }
        @media screen and (max-width: 768px) {
            h2{
                font-size: 25px;
            }
        }

        // @media screen and (max-width: 480px) {
        //     .product_item{
        //         .product_card{
        //             border-radius: 16px;
        //             background: #FFF;
        //             width: 100%;
        //             gap: 30px;
        //             h3{
        //                 color: #2B4594;
        //                 font-family: 'Inter';
        //                 font-size: 16px;
        //                 font-style: normal;
        //                 font-weight: 700;
        //                 line-height: 120%; /* 130.612% */                    
        //             }
        //         }
        //     }
        // }
    }
}
