.header_container{
    padding: 10px 20px;
    background: #FFF;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.30);
    position: fixed;
    width: 100%;
    top: 0;   
    z-index: 1000;  
}
.header_block{
   max-width: 1200px;
   margin: 0 auto;
   display: flex;
   align-items: center;
   justify-content: space-between;
   gap: 20px;

}
.headerTop_block{
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}
.header_block h5 {
    color: #2B4594;

    font-family: "Roboto";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
}
.headerTop_item{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 20px;
}
.header_block p {
    color: #2B4594;
    text-align: end;
    font-family: "Roboto";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
}
.headerTopItemIcon_block{
    display: flex;
    align-items: center;
    gap: 10px;
    
}
@media screen and (max-width: 768px) {
    .headerTopItemIcon_block:nth-child(2){
        display: none;

        
    }
    .header_block h5 {
        color: #2B4594;
        
        font-family: "Roboto";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; 
    }
    .headerTop_block {
         
    }
    .header_logo{
        width: 100px;
    }
}
@media screen and (max-width: 480px) {
    /* .header_block{
        flex-direction: column;
        
     } */
}