.menu_container{
    background: #F2F6FA;
    padding: 20px;
    margin-top: 60px;
}
.menu_block{
    background: #0073E6;
    padding: 20px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;

}
.menu_item{
  display: flex;
  align-items: center;
  gap: 10px;
}
.menu_item img {
  width: 20px;
}
.menu_item p {
    color: #FFF;
    text-align: center;
    font-family: 'Roboto';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 123.077% */
}

@media screen and (max-width: 920px) {
    .menu_item p { 
        font-size: 16px; 
    }
}
@media screen and (max-width: 840px) {
    .menu_item p { 
        font-size: 14px; 
    }
}
@media screen and (max-width: 768px) {
    .menu_block{
        flex-direction: column;
        padding: 20px 0;
        gap: 10px;
    }
    .menu_item{
        border-bottom: 1px solid white;
        width: 100%;
        justify-content: center;
        padding-bottom: 10px;
      }
    .menu_item:nth-child(6){
        border-bottom: none;
        padding-bottom: 0px;
      }
}