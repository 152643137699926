.footer{
    background: #F2F6FA;
    .footer_container{
        height: 366px;
        background-image: url('../../Asset/Footer.png') ; 
        padding: 20px;
        padding-top: 20px;
        
        .footer_block{
            max-width: 670px;
            margin: 0 auto;
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 20px;
            padding-top: 30px;
            .footerSubscribe_block{
                  width: 100%;
                .footerSubscribe_item{
                    margin-top: 50px;
                    p{
                        color: #595959;
                        font-family: 'Roboto';
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 140%; /* 140% */
                        letter-spacing: 0.03px;
                        text-align: center;
                    }
                    .footerSubscribeInput_block{
                        width: 100%;
                        height: 50px;
                        border-radius: 15px;
                        background: #FFF;
                        display: flex;
                        align-items: center; 
                        margin-top: 20px;
                        input{
                           flex: 70%;
                           height: 100%;
                           background: none;
                           border: none;
                           outline: none;
                           padding: 0 10px 0 20px;
                           color: #727272;
                           font-family: 'Roboto';
                           font-size: 16px;
                           font-style: normal;
                           font-weight: 500;
                           line-height: 120%;      
                                     
                        }
                        button{
                            height: 100%;
                            flex: 30%;
                            border-radius: 15px;
                            background: #EB3C11;
                            border: none;
                            cursor: pointer;
                            color: #FFF;
                            font-family: 'Roboto';
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 120%; /* 125% */
                            text-transform: uppercase;      
                            &:active{
                                transform: scale(.98);
                            }                  
                        }
                    }
                }
                .footerSokial_block{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 20px;
                    margin-top: 20px;
                    .footerSokial_item{ 
                       background-color: white;
                       border-radius: 50%;
                       width: 40px;
                       height: 40px;
                       display: flex;
                       align-items: center;
                       justify-content: center;
                       cursor: pointer;
                       &:active{
                        transform: scale(.98);
                       }
                    }
                }
            }
            @media screen and (max-width: 480px) {
                .footer_logo{
                    width: 100%;
                }
                .footerSubscribe_block{
                    .footerSubscribe_item{
                 
                        .footerSubscribeInput_block{
                            width: 100%;
                            height: 40px;
                            border-radius: 15px;
                            background: #FFF;
                            display: flex;
                            align-items: center; 
                            margin-top: 20px;
                            input{
                               flex: 70%;
                               height: 100%;
                               padding: 0 10px 0 20px;
                               font-size: 14px;
                       
                            }
                            button{
                                height: 100%;
                                flex: 40%;
                                font-size: 13px;
                     
                            }
                        }
                    }
                }
    
            }
            @media screen and (max-width: 350px) {
                .footerSubscribe_block{
                    .footerSubscribe_item{
                 
                        .footerSubscribeInput_block{
                            input{
                               flex: 50%;
                               height: 100%;
                               padding: 0 ;
                               text-indent: 10px;
                               font-size: 14px;
                       
                            }
                            button{
                                height: 100%;
                                flex: 60%;
                                font-size: 11px;
                     
                            }
                        }
                    }
                }
    
            }
        }
        @media screen and (max-width: 768px) {
            background: #F6F344;
             
        }
    }
}