.brends{
    background: #F2F6FA;
    .brends_container{
        max-width: 1200px;
        margin: 0 auto;
        padding: 50px 20px;

        .brends_title{
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            h1{
                color: #2B4594;
                font-family: 'Inter';
                font-size: 35.156px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%; /* 136.533% */            
            }
            button{
                background-color: #0073E6;
                border: none;
                color: #FFF;
                font-family: 'Inter';
                font-size: 13.875px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%; /* 144.144% */
                width: 180px;
                height: 50px;
                cursor: pointer;
                border-radius: 8px;
                &:active{
                    transform: scale(.98);
    
                }            
            }
        }
        .brends_content{
            overflow-x: auto;
            overflow-y: hidden;
            width: 100%;
            .brends_block{
                
                display: flex;
                align-items: center;
                gap: 20px;
                flex-wrap: wrap;
                // justify-content: space-between;
                margin-top: 50px;
                .brends_item{
                    border-radius: 16px;
                    background: #FFF;
                    width: 148px;
                    height: 120px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img{
                        width: 140px;
                        height: 80px;
                    }            
                }
            }
        }
        @media screen and (max-width: 1200px) {
            .brends_content{

                .brends_block{
                    width: 1200px;
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    .brends_item{
                        border-radius: 16px;
                        background: #FFF;
                        width: 180px;
                        height: 120px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img{
                            width: 140px;
                            height: 80px;
                        }            
                    }
                }
            }


        }
        @media screen and (max-width: 768px) {
            position: relative;
            padding-bottom: 50px;
            .brends_title{
                h1{
                    font-size: 25px;        
                }
                button{
                    width: 90%;
                    position: absolute; 
                    bottom: -20px;   
                    left: center;     
                }
            }

        }
    }
}