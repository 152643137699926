

.sliderTop_container{
    background: #FFF;
    padding: 20px;
}
.sliderTop_block{
    max-width: 1200px;
    margin: 0 auto;
}
.sliderTop_block img{
    width: 100%;
    border-radius: 20px;
    /* height: 300px; */
}

/* grid-column: 1 / span 2;
grid-row: 1; */