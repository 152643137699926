.grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 10px; 
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
  }
  
  .block {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: lightgray;
  }
  
  .block-1 {
    grid-column: 1 / span 1;
    grid-row: 1 / span 2;
    img{
        width: 100%;
        height: 100%;
    }
  }
  
  .block-2 {
    img{
        width: 100%;
        height: 100%;
    }
    grid-column: 2 / span 1;
    grid-row: 1 / span 1;
  }
  
  .block-3 {
    img{
        width: 100%;
        height: 100%;
    }
    grid-column: 3 / span 2;
    grid-row: 1 / span 1;
  }
  
  .block-4 {
    img{
        width: 100%;
        height: 100%;
    }
    grid-column: 2 / span 2;
    grid-row: 2 / span 1;
  }
  
  .block-5 {
    img{
        width: 100%;
        height: 100%;
    }
    grid-column: 4 / span 1;
    grid-row: 2 / span 1;
  }

  @media screen and (max-width: 768px){
    .grid-container{
        display: none;
    }
  }