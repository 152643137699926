.BrendSlider_container{
    background: #FFF;
    padding: 35px 20px;
    .BrendSlider_block{
        max-width: 1200px;
        margin: 0 auto;
        h2{
            color: #2B4594;
            font-family: 'Inter';
            font-size: 35.156px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;             
        }
        .BrendSlider_item{
            margin-top: 20px;
            img{
                width: 48%; 
                padding: 0 10px;
            }
          
        
        }
        @media screen and (max-width: 768px) {
            h2{
                font-size: 30px;
            }
            .BrendSlider_item{ 
                img{ 
                    padding: 0 ;
                }
              
            
            }
        }
        @media screen and (max-width: 768px) {
            h2{
                font-size: 25px;
            }

        }
    }
}