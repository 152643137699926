.BabyMobi_container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    gap: 10px; 
    padding: 20px;
    display: none;
    margin: 20px 0;
    .BabyMobi_block{
        grid-column: 1 / span 2;
        grid-row: 1 / span 1;
        img{
            width: 100%;
        }
        &:nth-child(2){
            grid-column: 1 / span 1;
            grid-row: 2 / span 2;
        }
        &:nth-child(3){
            grid-column: 2 / span 1;
            grid-row: 2 / span 1;
        }
        &:nth-child(4){
            grid-column: 2 / span 1;
            grid-row: 3 / span 1;
        }
        &:nth-child(5){
            grid-column: 1 / span 2;
            grid-row: 4 / span 1;
        }
    }
    @media screen and (max-width: 768px) {
        display: grid;
    }
}